import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['shouldBuildNewForm']
  connect() {
    const employeesContainer = document.getElementById("employees-container");
    if (!employeesContainer) return;
    const observer = new MutationObserver(() => {
      if (employeesContainer.children.length <= 1) {
        this.shouldBuildNewFormTarget.value = true;
      }
      else{
        this.shouldBuildNewFormTarget.value = false;
      }
    });
    observer.observe(employeesContainer, { childList: true });
  }
}
