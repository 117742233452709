import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["editor"]
  static values = {
    placeholders: Object
  }

  connect() {
    this.addDropdownToToolbar()
  }

  addDropdownToToolbar() {
    const toolbar = this.editorTarget.toolbarElement

    const dropdown = document.createElement("select")
    dropdown.classList.add("trix-dropdown")
    dropdown.setAttribute("aria-label", "Insert Placeholder")

    const defaultOptionElement = document.createElement("option")
    defaultOptionElement.disabled = true
    defaultOptionElement.selected = true
    defaultOptionElement.value = ""
    defaultOptionElement.textContent = "Select Placeholder to insert in content"
    dropdown.appendChild(defaultOptionElement)

    Object.entries(this.placeholdersValue).forEach(([key, { title }]) => {
      const optionElement = document.createElement("option")
      optionElement.value = key
      optionElement.textContent = title
      dropdown.appendChild(optionElement)
    })

    const toolbarGroup = toolbar.querySelector("[data-trix-button-group='text-tools']")
    toolbarGroup.appendChild(dropdown)

    dropdown.addEventListener("change", (event) => {
      this.insertPlaceholder(event.target.value)
      dropdown.value = ""
    })
  }

  insertPlaceholder(placeholder) {
    const editor = this.editorTarget.editor
    editor.insertString(placeholder)
  }
}
