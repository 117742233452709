import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["refreshButton"];

  connect() {
    // Wait 3 seconds before checking the state.
    setTimeout(() => {
      // If the refresh button is still present and visible, trigger a click.
      if (this.hasRefreshButtonTarget && this.refreshButtonTarget.offsetParent !== null) {
        this.refreshButtonTarget.click();
      }
    }, 5000);
  }
}
