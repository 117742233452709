import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['dataProvider', 'pmsTypeVisualPicker','pmsType', 'form', 'pmsTypeDentrix', 'pmsTypeOpendental', 'pmsTypeDentrixAscend', 'pmsTypeEaglesoft', 'others'];
  connect() {
    this.handleDataProviderSelection();
    var pms_values = ['dentrix', 'dentrix_ascend', 'opendental', 'eaglesoft']
    if (pms_values.includes(this.pmsTypeVisualPickerTarget.value) || this.pmsTypeVisualPickerTarget.value == ''){
      this.othersTarget.classList.add('d-none'); 
    }
    else{
      this.othersTarget.classList.remove('d-none'); 
    }
  }

  handleDataProviderSelection(){
    $(this.pmsTypeTarget).on('change', ()=>{
      var pmsTypeValue = this.pmsTypeTarget.value;
      this.selectDataProvider(pmsTypeValue);
    })
  }

  handleDataProviderSelectionForRadio(event){
    this.pmsTypeVisualPickerTarget.value = event.target.dataset.typeValue;
    var pmsTypeValue = this.pmsTypeVisualPickerTarget.value;
    if (pmsTypeValue === 'others') {
      this.othersTarget.classList.remove('d-none'); 
      this.pmsTypeTarget.disabled = false;
      this.dataProviderTarget.value = 'sikka';
    }
    else{
      this.selectDataProvider(pmsTypeValue);
      this.formTarget.requestSubmit();
    }
    
  }

  selectDataProvider(pmsTypeValue){
    if (pmsTypeValue == 'dentrix'){
      this.othersTarget.classList.add('d-none')
      this.dataProviderTarget.value = 'bahammas';
    }
    else if(pmsTypeValue == 'dentrix_ascend'){
      this.othersTarget.classList.add('d-none')
      this.dataProviderTarget.value = 'dentrix_ascend_provider';
    }
    else if(pmsTypeValue == 'open_dental_cloud' || pmsTypeValue == 'opendental'){
      this.othersTarget.classList.add('d-none')
      this.dataProviderTarget.value = 'open_dental';
    }
    else if(pmsTypeValue == 'eaglesoft'){
      this.othersTarget.classList.add('d-none')
      this.dataProviderTarget.value = 'velox';
    }
    else{
      this.othersTarget.classList.remove('d-none')
      this.dataProviderTarget.value = 'sikka';
    }
  }
}
