import { Controller } from "@hotwired/stimulus";
import { Tab } from "bootstrap";

// Connects to data-controller="tab"
export default class extends Controller {
  static targets = ["trigger"];

  connect() {
    this.triggerTargets.forEach(button => {
      button.addEventListener("click", this.activateTab.bind(this));
    });
  }

  activateTab(event) {
    event.preventDefault();
    const tabId = event.currentTarget.dataset.tab;

    const tab = document.querySelector(`a[href='${tabId}']`);
    if (tab) {
      new Tab(tab).show();
    }
  }
}
