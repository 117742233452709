import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["stickyComponent"]; // Define the sticky target


  connect() {
    this.observeVisibility(); // Call the observer
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.element) // Disconnect the observer
  }

  observeVisibility() {
    const stickyComponent = this.stickyComponentTarget;
    this.intersectionObserver =  new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          stickyComponent.style.position = "static";
        } else {
          stickyComponent.style.position = "fixed";
          stickyComponent.style.bottom = "0";
          stickyComponent.style.left = "0";
          stickyComponent.style.width = "100%";
        }
      });
    });

    this.intersectionObserver.observe(this.element);
  }
}
