practices = require('./practice_controller')

(->
  window.StripeElements ||= {}

  StripeElements.init = (stripe_publishable_key, controller_name, connected_account) ->
    stripe = if connected_account
      Stripe(stripe_publishable_key, { stripeAccount: connected_account })
    else
      Stripe(stripe_publishable_key)

    appearance =
      theme: 'stripe'

    switch controller_name
      when "financial_arrangements"
        # Create the Payment Element
        payment_element_form = document.getElementById('payment-element-form')
        secretValue = payment_element_form.getAttribute('data-secret')
        return_url = payment_element_form.getAttribute('data-return-url')
        elements = stripe.elements({ clientSecret: secretValue, appearance: appearance })
        paymentElement = elements.create('payment')
        paymentElement.mount('#payment-element')

        payment_element_form.addEventListener 'submit', (event) ->
          event.preventDefault()
          StripeElements.loading true

          stripe.confirmPayment({
            elements: elements,
            confirmParams: {
              return_url: return_url
            }
          }).then ({ error }) ->
            StripeElements.loading false
            if error
              StripeElements.showError(error.message)
      when "practices"
        elements = stripe.elements({ appearance: appearance })
        CouponCodeProcessor.initialize('practices')

        style = {
          base: {
            color: "#32325d",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
              color: "#32325d"
            }
          },
          invalid: {
            fontFamily: 'Arial, sans-serif',
            color: "#fa755a",
            iconColor: "#fa755a"
          }
        }

        card = elements.create("card", { style: style })
        card.mount("#card-element")

        card.on 'change', (event) ->
          document.querySelector('button').disabled = event.empty
          document.querySelector('#card-error').textContent = if event.error then event.error.message else ''
          return

        button = document.querySelector("#payment-form button");
        button?.addEventListener 'click', (event) ->
          event.preventDefault()
          StripeElements.loading(true)
          stripe.createToken(card).then (result) ->
            if result.error
              StripeElements.showError result.error.message
              StripeElements.loading false
            else
              handleFormSubmission(result.token.id, event, card, stripe, "practices")

  handleFormSubmission = (tokenId, event, card, stripe, controller_name) ->
    tokenInput = document.getElementById('stripe-token')
    tokenInput.value = tokenId
    form = $(event.target).closest("form")
    if form?.attr("method") == "post"
      $.ajax
        url: form.attr("action")
        method: form.attr("method")
        data: $(form).serialize()
        success: (response) ->
          handleTurboStreams(response, card, stripe, controller_name)
    else
      StripeElements.loading(false)

  handleTurboStreams = (response, card, stripe, controller_name) ->
    parser = new DOMParser()
    htmlDoc = parser.parseFromString(response, "text/html")
    turboStreams = htmlDoc.querySelectorAll('turbo-stream')

    errorTargetElement = false

    for stream in turboStreams
      action = stream.getAttribute('action')
      target = stream.getAttribute('target')
      template = stream.querySelector('template').innerHTML
      targetElement = document.querySelector("##{target}")
      if action is 'update' and targetElement
        targetElement.innerHTML = template
        errorTargetElement = true if target == 'practice_registration_errors'
        StripeElements.loading(false)

    unless errorTargetElement
      practiceStripeUtils = document.getElementById('practice_stripe_utils')
      firstInputField = practiceStripeUtils.querySelector('input')
      redirectPath = firstInputField.dataset.stripeUtilsRedirectPath
      clientSecret = firstInputField.dataset.stripeUtilsClientSecret
      hasSavedPaymentMethods = firstInputField.dataset.stripeUtilsHasSavedPaymentMethods
      PaymentProcessor.initialize(redirectPath, clientSecret, hasSavedPaymentMethods)
      PaymentProcessor.pay_with_card(card, stripe, controller_name)

  StripeElements.loading = (isLoading) ->
    if isLoading
      document.querySelector('button').disabled = true
      document.querySelector('#spinner').classList.remove 'hidden'
      document.querySelector('#button-text').classList.add 'hidden'
    else
      document.querySelector('button').disabled = false
      document.querySelector('#spinner').classList.add 'hidden'
      document.querySelector('#button-text').classList.remove 'hidden'

  StripeElements.showError = (errorMsgText) ->
    StripeElements.loading false
    errorMsgContainer = document.querySelector("#card-error-container")
    if errorMsgContainer
      errorMsgContainer.classList.remove('d-none')
      errorMsg = document.querySelector("#card-error-msg")
      errorMsg.textContent = errorMsgText
      setTimeout (->
        errorMsgContainer.classList.add('d-none')
      ), 8000

).call this
