import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "resultStatus", "datepickerAppointmentAt",
    "appointmentTrackingType", "pmsData",
    "placeholder", "appointmentList",
    "scriptId", "scriptFrame", 'submitBtn',
    "followUPType", "followUPContainer",
    "voicemailFollowUpRadio", "spokeNoApptFollowUpRadio",
    "voicemailContainer", "spokeMadeApptContainer", "situationType",
    "spokeNoApptContainer", "otherNoApptContainer", "notes",
    "spokeNoApptMinFollowUpDate", "followUPTypeEmail",
    "voicemailMinFollowUpDate", "spokeNoApptScheduledNextOutreachAt",
    "voicemailScheduledNextOutreachAt"
  ]

  connect() {
    ToggleCheckbox.initialize();
    datetime_picker.time_input();
    this.attachFollowUpRadioListeners();
    this.initializeDateField(this.spokeNoApptScheduledNextOutreachAtTarget, this.spokeNoApptMinFollowUpDateTarget);
    this.initializeDateField(this.voicemailScheduledNextOutreachAtTarget, this.voicemailMinFollowUpDateTarget);

    if (this.hasSituationTypeTarget) {
      this.handleSituationType()
      $(this.situationTypeTarget).find('input[type="radio"]').on('change', () => {
        this.handleSituationType()
      })
    }

    if (this.hasFollowUPTypeTarget) {
      this.followUPTypeTargets.forEach(_target => {
        this.handleFollowUPType(_target);

        $(_target).find('input[type="radio"]').on('change', () => {
          this.handleFollowUPType(_target);
        });
      });
    }


    $(this.resultStatusTarget).find('input[type="radio"]').on('change', () => {
      var resultStatusValue = $(this.resultStatusTarget).find('input[type="radio"]:checked').val()

      this.handleTargetProp(this.voicemailContainerTarget, resultStatusValue === "voicemail")
      this.handleTargetProp(this.spokeNoApptContainerTarget, resultStatusValue === "spoke_no_appt")
      this.handleTargetProp(this.otherNoApptContainerTarget, resultStatusValue === "other_no_appt")

      this.allowSubmitBtn(true);
      this.handleSpokeMadeAppt(resultStatusValue)
      this.handleNotes()
      this.handleFollowUPContainer()
    });

    $(this.appointmentListTarget).on('change', () => {
      this.allowSubmitBtn(true);
      const id = this.appointmentListTarget.querySelector('input:checked').value;
      if ($(this.resultStatusTarget).closest('form')[0].getElementsByClassName('selected_future_appointment').length > 0) {
        $($(this.resultStatusTarget).closest('form')[0]).append('<input type="hidden" name="event" class="event" value="scheduled_future_appointment" />');
        $($(this.resultStatusTarget).closest('form')[0].getElementsByClassName('selected_future_appointment')[0]).val(id);
        $($(this.resultStatusTarget).closest('form')[0].getElementsByClassName('patient_decision_type')[0]).val("scheduled");
      }
    });
  }

  attachFollowUpRadioListeners() {
    if (this.hasVoicemailFollowUpRadioTarget || this.hasSpokeNoApptFollowUpRadioTarget) {
      const radioTargets = [
        this.voicemailFollowUpRadioTarget,
        this.spokeNoApptFollowUpRadioTarget
      ];

      radioTargets.forEach(target => {
        if (target) {
          $(target).find('input[type="radio"]').on('change', this.handleFollowUPContainer.bind(this));
        }
      });
    }
  }

  handleFollowUPContainer() {
    const resultStatusValue = $(this.resultStatusTarget).find('input[type="radio"]:checked').val();

    const isEligible = ["voicemail", "spoke_no_appt"].includes(resultStatusValue);
    const isEnabled = isEligible && this.getFollowUpRadioValue(resultStatusValue);

    this.handleTargetProp(this.followUPContainerTarget, isEnabled);

    if (isEnabled) {
      this.handleFollowUPType(this.followUPContainerTarget);

      setTimeout(() => {
        if (this.hasFollowUPContainerTarget && this.followUPContainerTarget.offsetParent !== null) {
          this.followUPContainerTarget.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 100);
    }
  }

  getFollowUpRadioValue(resultStatusValue) {
    const radioTargetMap = {
      voicemail: this.voicemailFollowUpRadioTarget,
      spoke_no_appt: this.spokeNoApptFollowUpRadioTarget
    };

    const target = radioTargetMap[resultStatusValue];
    if (target) {
      const selectedValue = $(target).find('input[type="radio"]:checked').val();
      this.submitBtnTarget.disabled = selectedValue === 'yes' ? true : false;
      return selectedValue === 'yes';
    }

    return false;
  }

  handleNotes() {
    const resultStatusValue = $(this.resultStatusTarget).find('input[type="radio"]:checked').val();
    const notesTarget = this.notesTarget;

    if (resultStatusValue === "voicemail" || resultStatusValue === "spoke_no_appt") {
      $(notesTarget).removeClass('d-none');
      $(notesTarget).prop('required', resultStatusValue === "spoke_no_appt");
    } else {
      $(notesTarget).addClass('d-none');
      $(notesTarget).prop('required', false);
    }
  }

  allowSubmitBtn = (enabled) => {
    const resultStatusValue = $(this.resultStatusTarget).find('input[type="radio"]:checked').val();
    const submitBtn = this.submitBtnTarget;
    if (!resultStatusValue) {
      submitBtn.disabled = true;
      submitBtn.classList.toggle("disabled", true);
      return;
    }
    submitBtn.disabled = !enabled;
    submitBtn.classList.toggle("disabled", !enabled);
  }

  handleTargetProp = (target, enabled) => {
    if (enabled) {
      $(target).removeClass('d-none');
      $('textarea:not(.not_required)', target).prop('required', true);
      $(target).find('input:not([type="checkbox"])').prop('required', true);
      $(target.getElementsByTagName('select')).prop('required', true);
      $(target.getElementsByTagName('textarea')).prop('disabled', false)
      $(target.getElementsByTagName('input')).prop('disabled', false)
      $(target.getElementsByTagName('select')).prop('disabled', false)
    } else {
      $(target).addClass('d-none');
      $('textarea:not(.not_required)', target).prop('required', false);
      $(target).find('input:not([type="checkbox"])').prop('required', false);
      $(target.getElementsByTagName('select')).prop('required', false);
      $(target.getElementsByTagName('textarea')).prop('disabled', true)
      $(target.getElementsByTagName('input')).prop('disabled', true)
      $(target.getElementsByTagName('select')).prop('disabled', true)
    }
  }

  handleSpokeMadeAppt = (selectedValue) => {
    if (selectedValue === "spoke_made_appt") {
      $(this.spokeMadeApptContainerTarget).removeClass('d-none');
      $(this.appointmentTrackingTypeTarget.getElementsByTagName('input')).prop('disabled', false)
      this.handleAppointmentTracking()
    } else {
      $(this.spokeMadeApptContainerTarget).addClass('d-none');
      $(this.appointmentListTarget.getElementsByTagName('input')).prop('disabled', true);
      $(this.appointmentTrackingTypeTarget.getElementsByTagName('input')).prop('disabled', true)
      $(this.placeholderTarget.getElementsByTagName('select')).prop('disabled', true)
      $(this.placeholderTarget.getElementsByTagName('select')).prop('required', false)
      $(this.appointmentTrackingTypeTarget.getElementsByTagName('input')).prop('required', false)

      this.handleTxpAppointmentFields(false)
    }
  }

  handleAppointmentTracking = () => {
    if (!$(this.appointmentTrackingTypeTarget).find('input[type="radio"]').is(":checked")) {
      $(this.appointmentTrackingTypeTarget).find('input[type="radio"]:first').prop("checked", true)
    }

    let appointmentType = $(this.appointmentTrackingTypeTarget).find('input[type="radio"]:checked').val()
    switch (appointmentType) {
      case 'pms_data':
        this.handleFutureAppointmentList()
        break;
      case 'placeholder':
        this.handleEstimatedAppointment()
        break;
    }
  }

  handleFutureAppointmentList = () => {
    $(this.pmsDataTarget).removeClass('d-none');
    this.handleTargetProp(this.placeholderTarget, false)
    this.handleTxpAppointmentFields(true)

    if (this.appointmentListTarget.querySelectorAll('input:checked').length < 1) {
      this.allowSubmitBtn(false);
    }
  }

  handleEstimatedAppointment = () => {
    $(this.pmsDataTarget).addClass('d-none');
    this.handleTargetProp(this.placeholderTarget, true)
    this.handleTxpAppointmentFields(false)

    if ($(this.datepickerAppointmentAtTarget).find('input').val()) {
      this.allowSubmitBtn(true);
    } else {
      this.allowSubmitBtn(false);
    }

    var that = this
    $(that.datepickerAppointmentAtTarget).find('input').flatpickr({
      onChange: function (selectedDates, dateStr, instance) {
        if ($(that.resultStatusTarget).find('input[type="radio"]:checked').val() != "spoke_made_appt" || $(that.appointmentTrackingTypeTarget).find('input[type="radio"]:checked').val() != 'placeholder') return;

        if (selectedDates.length > 0) {
          that.allowSubmitBtn(true);
        } else {
          that.allowSubmitBtn(false);
        }
      }
    });
  }

  handleTxpAppointmentFields = (enabled) => {
    if ($(this.resultStatusTarget).closest('form')[0].getElementsByClassName('selected_future_appointment').length <= 0) return;

    $($(this.resultStatusTarget).closest('form')[0].getElementsByClassName('selected_future_appointment')[0]).prop('disabled', !enabled);
    $($(this.resultStatusTarget).closest('form')[0].getElementsByClassName('event')[0]).prop('disabled', !enabled);
    $($(this.resultStatusTarget).closest('form')[0].getElementsByClassName('patient_decision_type')[0]).prop('disabled', !enabled);
  }

  handleSituationType = () => {
    if (!this.situationTypeTarget) return;

    const communication_script_id = $(this.situationTypeTarget).find('input[type="radio"]:checked').data('communication-script-id')
    this.scriptIdTarget.value = communication_script_id
    const $frame = $(this.scriptFrameTarget)
    $frame.attr('src', $frame.attr('src').replace(/\/\d+\//, `/${communication_script_id}/`));
  }

  scrollEmailPreview() {
    if (!this.hasFollowUPTypeEmailTarget) return;

    const iframeDocument = this?.followUPTypeEmailTarget?.contentWindow?.document;
    const targetElement = iframeDocument?.getElementById("rep-src-iframe");
    if (targetElement) targetElement?.scrollIntoView({ behavior: "smooth" });
  }

  handleFollowUPType = (target) => {
    const followUPTypeValue = $(target).find('input[type="radio"]:checked').val();
    if (!followUPTypeValue) return;

    target.querySelectorAll('input[type="radio"]').forEach(element => {
      if (element.value == followUPTypeValue) {
        $(`.${element.id}_container`).removeClass('d-none');
      } else {
        $(`.${element.id}_container`).addClass('d-none');
      }
    });

    if (followUPTypeValue === 'text_message') {
      this.submitBtnTarget.disabled = true;
    } else {
      this.scrollEmailPreview()
      this.submitBtnTarget.disabled = false;
    }
  }

  handleMessageConfirmationContainer(){
    this.submitBtnTarget.disabled = false
  }

  initializeDateField(dateTarget, textTarget){
    textTarget.textContent = this.formatDateVerbose(dateTarget.value);

    $(dateTarget).on('change', ()=>{
      textTarget.textContent = this.formatDateVerbose(dateTarget.value);
    });
  }

  formatDateVerbose(date) {
    const givenDate = new Date(date);
    const today = new Date();

    // Reset time to compare only dates
    today.setHours(0, 0, 0, 0);
    givenDate.setHours(0, 0, 0, 0);

    const diffInMs = givenDate - today;
    const diffInDays = Math.round(diffInMs / (1000 * 60 * 60 * 24));

    if (diffInDays === 0) {
      return "Today";
    } else if (diffInDays > 0) {
      return `in ${diffInDays} day${diffInDays > 1 ? "s" : ""}`;
    } else {
      return `${Math.abs(diffInDays)} day${Math.abs(diffInDays) > 1 ? "s" : ""} ago`;
    }
  }
}
