import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "emailForm" ]

  connect() {
    this.emailFormTarget.style.display = 'none'
  }

  showEmailForm() {
    this.emailFormTarget.style.display = 'block'
  }
}
