import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["details"];

  connect() {
    document.querySelectorAll('.visual-picker input[type="radio"]').forEach(input => {
      input.checked = false
    })
  }

  showDetails(event) {
    event.preventDefault()
    const selectedCard = event.currentTarget;

    document.querySelectorAll('.visual-picker input[type="radio"]').forEach(input => {
      input.checked = false;
    })
    const radioButton = selectedCard.querySelector("input[type='radio']");
    if (radioButton) {
      radioButton.checked = true;
    }
    const tier = selectedCard.getAttribute("data-tier");
    const duration = selectedCard.getAttribute("data-duration");
    this.reloadForm({ tier, duration });
  }

  reloadForm(params) {
    $("#new_card_payment").html(`
      <div class="spinner-border text-info my-4" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    `);

    $('#practice-pricing').attr("disabled", true);

    $.ajax({
      type: 'GET',
      url: `${window.location.pathname}`,
      dataType: 'script',
      data: params,
      success: function () {}
    });
  }
}
